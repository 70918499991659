/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useMemo } from 'react';

import { SlateReactPresentation } from 'slate-react-presentation';

import type { Theme } from '@coursera/cds-core';

import { cdsToCMLStyles } from 'bundles/cml/legacy/components/cds/cdsToCMLStyles';
import type { CmlContent as NaptimeCmlContent, VariableData } from 'bundles/cml/legacy/types/Content';
import { getCodeBlockIndices } from 'bundles/cml/shared/components/code/utils';
import type { CmlContent } from 'bundles/cml/shared/types/CmlContent';
import type { AssetManager as AssetManagerType } from 'bundles/cml/shared/types/assetManager';
import AssetManager from 'bundles/cml/shared/utils/AssetManager';
import type { WidgetManagerType } from 'bundles/cml/shared/utils/WidgetManager';
import { createWidgetManager } from 'bundles/cml/shared/utils/WidgetManager';
import { useCMLToSlate } from 'bundles/cml/viewer/components/hooks/useCMLToSlate';
import { useRenderElement, useRenderTextElement } from 'bundles/cml/viewer/components/hooks/useRenderElement';
import { AssetContext } from 'bundles/cml/viewer/context/assetContext';
import { CodeBlockContext } from 'bundles/cml/viewer/context/codeBlockContext';
import { FillableBlanksContext } from 'bundles/cml/viewer/context/fillableBlanksContext';
import { WidgetContext } from 'bundles/cml/viewer/context/widgetContext';
import type { WidgetContextType } from 'bundles/cml/viewer/context/widgetContext';

export type Props = {
  cml?: CmlContent | NaptimeCmlContent;
  enableFillableBlanksV2?: boolean;
  contentId?: string;
  variableData?: VariableData;
  assetManager?: AssetManagerType;
  widgetManager?: WidgetManagerType;
  videoSubtitlesEnabled?: boolean;
};

const styles = {
  viewer: (theme: Theme) => css`
    ${cdsToCMLStyles(theme)}
    white-space: pre-wrap;
    overflow-wrap: break-word;
  `,
};

const CMLViewer: React.FC<Props> = (props) => {
  const {
    cml,
    contentId,
    variableData,
    assetManager = AssetManager,
    enableFillableBlanksV2,
    widgetManager,
    videoSubtitlesEnabled,
  } = props;
  const value = useCMLToSlate(cml, variableData, videoSubtitlesEnabled);

  const renderElement = useRenderElement();
  const renderLeaf = useRenderTextElement();

  const codeBlockIndices = useMemo(() => getCodeBlockIndices(value), [value]);
  const defaultWidgetManager = useMemo(() => createWidgetManager(), []);

  return (
    <div id={contentId} data-testid="cml-viewer" css={styles.viewer}>
      <AssetContext.Provider value={{ assetManager }}>
        <CodeBlockContext.Provider value={{ codeBlockIndices }}>
          <FillableBlanksContext.Provider value={{ enableFillableBlanksV2 }}>
            <WidgetContext.Provider value={{ widgetManager: widgetManager || defaultWidgetManager }}>
              <SlateReactPresentation value={value} renderElement={renderElement} renderLeaf={renderLeaf} />
            </WidgetContext.Provider>
          </FillableBlanksContext.Provider>
        </CodeBlockContext.Provider>
      </AssetContext.Provider>
    </div>
  );
};

export default CMLViewer;
