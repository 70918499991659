import * as React from 'react';

import config from 'js/app/config';

import { Button, Grid } from '@coursera/cds-core';
import { DownloadIcon, FullscreenTwoIcon, MinimizeScreenIcon } from '@coursera/cds-icons';

import { usePdfViewerContext } from 'bundles/cml/shared/components/asset/pdfViewer/PdfViewerContext';

import _t from 'i18n!nls/cml';

export const ToolbarRightSideActions: React.FC = () => {
  const { pdfViewerRef, pageStart, pageEnd, assetId } = usePdfViewerContext();

  const handleFullscreen = () => {
    const isFullscreen = !!document.fullscreenElement;

    if (!isFullscreen) {
      pdfViewerRef.current?.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  const handleDownload = () => {
    const downloadUrl = new URL(`api/rest/v1/asset/download/pdf/${assetId}`, config.url.base);
    if (pageStart && pageEnd) {
      downloadUrl.searchParams.append('pageStart', pageStart.toString());
      downloadUrl.searchParams.append('pageEnd', pageEnd.toString());
    }
    // this endpoint returns the truncated file directly as the response
    window.open(downloadUrl);
  };
  return (
    <Grid container item justifyContent="flex-end" alignItems="center" xs>
      <Grid item>
        <Button variant="ghost" onClick={handleDownload} title={_t('Download the provided PDF file')}>
          <DownloadIcon color="interactive" />
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="ghost"
          onClick={handleFullscreen}
          title={_t('Enter fullscreen mode')}
          data-testid="fullscreenButton"
        >
          {document.fullscreenElement ? (
            <MinimizeScreenIcon color="interactive" />
          ) : (
            <FullscreenTwoIcon color="interactive" />
          )}
        </Button>
      </Grid>
    </Grid>
  );
};
